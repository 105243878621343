import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="places-map"
export default class extends Controller {
  static targets = ['map', 'placeInput', 'addressInput', 'phoneNumberInput', 'urlInput', 'latitudeInput', 'longitudeInput'];

  connect() {
    this.markers = [];
    this.initMap();
  }

  async initMap() {
    const position = new google.maps.LatLng(parseFloat(this.element.dataset.latitude), parseFloat(this.element.dataset.longitude));

    const { Map } = await google.maps.importLibrary('maps');

    const map = new Map(this.mapTarget, {
      zoom: 15,
      center: position,
      mapId: 'map'
    });

    if (this.element.dataset.pinPlace === 'true') {
      this.addSingleMarker(position, map);
    }

    const { SearchBox } = await google.maps.importLibrary('places');

    const defaultBounds = new google.maps.LatLngBounds(position, position);

    const searchBox = new SearchBox(this.placeInputTarget, {
      bounds: defaultBounds
    });

    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      this.removeAllMarkers();

      places.forEach((place) => {
        this.addSingleMarker(place.geometry.location, map);
        map.setCenter(place.geometry.location);

        const address = place.formatted_address;
        const phoneNumber = place.formatted_phone_number;
        const name = place.name;
        const url = place.url;
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();

        this.addressInputTarget.value = address;
        this.phoneNumberInputTarget.value = phoneNumber;
        this.urlInputTarget.value = url;
        this.latitudeInputTarget.value = latitude;
        this.longitudeInputTarget.value = longitude;

        const nameInput = document.getElementById('place_name');
        if (nameInput.value.length === 0) {
          nameInput.value = name;
        }
      });
    });
  }

  async addSingleMarker(position, map) {
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
    const marker = new AdvancedMarkerElement({
      map: map,
      position: position
    });

    this.markers.push(marker);
  }

  removeAllMarkers() {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.markers = [];
  }
}
